import { Divider } from "@mui/material";
import PackageArticle from "components/PackageArticle";
import ServiceArticle from "components/ServiceArticle";
import { sortByDateAscending } from "helpers/sort";
import { Fragment } from "react";
import { useSelector } from "store";
import { selectArticlesByAppointmentIdWithSlotsDetails } from "store/slices/articles/articlesSelectors";
import { Appointment } from "types/Appointment";
import { ArticleWithDetails } from "types/Article";

type AppointmentCardArticlesProps = {
  appointmentId: Appointment["id"];
};

const AppointmentCardArticles = ({ appointmentId }: AppointmentCardArticlesProps) => {
  const articlesWithSlots = useSelector(
    selectArticlesByAppointmentIdWithSlotsDetails(appointmentId || "")
  );

  const flattenedAppointments = articlesWithSlots.map((article) => ({
    ...article,
    start: article.slots[0]?.start,
  }));

  const articleSortedSlots: ArticleWithDetails[] = sortByDateAscending(
    flattenedAppointments,
    "start"
  );

  const getArticle = (article: ArticleWithDetails) => {
    if (article.serviceVariant)
      return (
        <ServiceArticle
          article={article}
          shouldShowPrice={false}
          shouldShowSelectedEmployeeBox={false}
        />
      );
    else if (article.packageVariant)
      return <PackageArticle article={article} shouldShowPrice={false} isExpandable={false} />;
    else return null;
  };

  return (
    <>
      {articleSortedSlots.map((article) => (
        <Fragment key={article.id}>
          <Divider variant="fullWidth" />

          {getArticle(article)}
        </Fragment>
      ))}
    </>
  );
};

export default AppointmentCardArticles;
