import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import PriceBox from "components/PriceBox";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Appointment } from "types/Appointment";

type AppointmentDetailsFooterProps = {
  phoneNumber: string | null;
  notes: Appointment["notes"];

  totalPrice: number;
  downPayment: number;

  shouldShowRescheduleSection?: boolean;
  shouldShowGoToMyBookings?: boolean;
};

const AppointmentDetailsFooter = ({
  phoneNumber,
  notes,
  totalPrice,
  downPayment,
  shouldShowRescheduleSection = false,
  shouldShowGoToMyBookings = false,
}: AppointmentDetailsFooterProps) => {
  const { t } = useTranslation(["appointmentPage", "common"]);

  const navigate = useNavigate();

  const handleGoToBookings = () => {
    navigate("/profile/bookings");
  };

  return (
    <Grid container bgcolor="white" display="flex" flexDirection="column" gap={1} p={2}>
      {notes && (
        <>
          <Grid item xs={12} bgcolor="white" py={3}>
            <Typography variant="h4" fontWeight={700} pb={1.5}>
              {t("bookingNotes")}
            </Typography>
            <Typography variant="primary">{notes}</Typography>
          </Grid>
          <Divider variant="fullWidth" />
        </>
      )}

      {shouldShowRescheduleSection && phoneNumber && (
        <>
          <Grid item xs={12} bgcolor="white" py={3}>
            <Typography variant="h4" fontWeight={700} pb={1.5}>
              {t("rescheduleAppointment")}
            </Typography>
            <Typography variant="primary">{t("callSalonToReschedule")}</Typography>

            <Box display="flex" justifyContent="space-between" alignItems="center" pt={2}>
              <Typography variant="h5">{phoneNumber}</Typography>

              <Button href={`tel:${phoneNumber}`} size="small" variant="outlined">
                {t("call")}
              </Button>
            </Box>
          </Grid>

          <Divider variant="fullWidth" />
        </>
      )}

      <Box py={3}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" pb={1.5}>
            {t("common:totalServicesPrice")}
          </Typography>

          <PriceBox value={totalPrice} variant="h4" />
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" fontWeight={400}>
            {t("common:downPayment")}
          </Typography>

          <PriceBox value={downPayment} variant="h4" textSx={{ fontWeight: 500 }} />
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" fontWeight={400}>
            {t("common:payAtStore")}
          </Typography>

          <PriceBox value={totalPrice - downPayment} variant="h4" textSx={{ fontWeight: 500 }} />
        </Box>
      </Box>

      {shouldShowGoToMyBookings && (
        <Box pb={3}>
          <Button fullWidth onClick={handleGoToBookings} size="medium">
            {t("goToMyBookings")}
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default AppointmentDetailsFooter;
