import { Box, Button, Card as MuiCard, Typography, styled } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import { getAppointmentStatusIcon } from "helpers/appointment/getAppointmentStatusIcon";
import getFormattedDateAndTime from "helpers/datetime/getFormattedDateAndTime";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store";
import { selectOrganization, selectOutletById } from "store/selectors";
import { selectAppointmentById } from "store/slices/appointments/appointmentsSelectors";
import { Appointment } from "types/Appointment";

import AppointmentCardArticles from "./AppointmentCardArticles";

type AppointmentCardProps = {
  appointmentId: Appointment["id"];
  isAnUpcomingAppointment?: boolean;
  startTime: string;
};

const AppointmentCard = ({
  appointmentId,
  isAnUpcomingAppointment = false,
  startTime,
}: AppointmentCardProps) => {
  const { t } = useTranslation(["appointmentPage", "common", "userAppointmentsPage"]);
  const navigate = useNavigate();

  const appointment = useSelector(selectAppointmentById(appointmentId));
  const organization = useSelector(selectOrganization);
  const outlet = useSelector(selectOutletById(appointment?.outlet || 0));

  if (!appointment || !outlet) return <CentredSpinnerBox />;

  const formattedDate = getFormattedDateAndTime(startTime);

  const mapLink = outlet.address.mapLink;

  const goToAppointmentPage = () => {
    navigate(`/profile/bookings/${appointmentId}`);
  };

  return (
    <Card>
      <Box p={2}>
        <Typography variant="h4" fontWeight={500}>{`${t(organization?.t?.name || "")} - ${t(
          outlet.t.name
        )}`}</Typography>

        <Typography pt={1} variant="h4" color="primary" fontWeight={500}>
          {formattedDate}
        </Typography>

        <Box display="flex" alignContent="center" pt={2} gap={1}>
          {getAppointmentStatusIcon(appointment.status)}
          <Typography variant="primary">{t(`common:${appointment.status}`)}</Typography>
        </Box>

        <Box display="flex" flexDirection="row" gap={1} pt={2} pb={1} height="100%">
          <Button onClick={goToAppointmentPage} fullWidth size="small">
            {t("userAppointmentsPage:bookingDetails")}
          </Button>

          {isAnUpcomingAppointment && mapLink && (
            <Button href={mapLink} variant="outlined" size="small" fullWidth>
              {t("appointmentPage:directions")}
            </Button>
          )}
        </Box>
      </Box>

      <AppointmentCardArticles appointmentId={appointmentId} />
    </Card>
  );
};

export default AppointmentCard;

const Card = styled(MuiCard)(({ theme }) => ({
  boxShadow: theme.shadows.card,

  margin: theme.spacing(2, 0),

  "&:last-child": {
    marginBottom: 0,
  },
}));
