import { Box, Typography } from "@mui/material";
import { getAppointmentStatusIcon } from "helpers/appointment/getAppointmentStatusIcon";
import { useTranslation } from "react-i18next";
import { AppointmentStatusOptions } from "types/Appointment";
import { AppointmentStatus } from "types/AppointmentStatus";

type AppointmentPageSuccessBannerProps = {
  status: AppointmentStatusOptions;
};

export default function AppointmentPageSuccessBanner({
  status,
}: AppointmentPageSuccessBannerProps) {
  const { t } = useTranslation(["appointmentPage", "common"]);

  return (
    <Box
      bgcolor="primary.main"
      position="fixed"
      bottom="0"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      zIndex={1400}
    >
      <Box
        display="flex"
        width="100%"
        minHeight="190px"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        color="white"
        rowGap={1}
      >
        {getAppointmentStatusIcon(status, {
          fill: "white",
          width: 61,
          height: 61,
        })}

        <Typography variant="h4" align="center">
          {t("yourBookingIs", { status: t(`common:${status}`) })}
        </Typography>

        {(status === AppointmentStatus.New || status === AppointmentStatus.Confirmed) && (
          <Typography variant="primary" align="center">
            {t("seeYouSoon")}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
