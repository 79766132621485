import { Box, Divider, Grid, Stack, Typography, styled } from "@mui/material";
import AppointmentAddressSection from "components/AppointmentAddressSection";
import AppointmentOrganizationHeader from "components/AppointmentOrganizationHeader";
import { CallToActionLink } from "components/CallToActionLink";
import CancelAppointmentModal from "components/CancelAppointmentModal";
import CancellationPolicySection from "components/CancellationPolicySection";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import SmallMPBanner from "components/SmallMPBanner";
import { getAppointmentStatusIcon } from "helpers/appointment/getAppointmentStatusIcon";
import getFormattedDateAndTime from "helpers/datetime/getFormattedDateAndTime";
import { getUseAppLink } from "helpers/getMarketplaceAppLink";
import dontForwardProps from "helpers/styles/dontForwardProps";
import { useDeviceDetection } from "hooks/useDeviceDetection";
import { DateTime } from "luxon";
import CancelBookingCard from "pages/AppointmentPage/CancelBookingCard";
import { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "store";
import { getNormalizedAppointments } from "store/globalThunks";
import { selectOrganization, selectOutletById } from "store/selectors";
import { selectAppointmentById } from "store/slices/appointments/appointmentsSelectors";
import { selectArticlesByAppointmentId } from "store/slices/articles/articlesSelectors";
import { selectArticleSlotsByArticleIds } from "store/slices/articleSlots/articleSlotsSelectors";
import { clearCart } from "store/slices/cart/cartSlice";
import {
  selectOnlinePaymentIsLoading,
  selectOnlinePaymentsByAppointmentId,
} from "store/slices/onlinePayments/onlinePaymentsSelectors";
import { trackEvent } from "tracking";
import { AppointmentStatus } from "types/AppointmentStatus";
import { checkIsHomeServiceOrganization } from "utils/organization";

import AppointmentDetailsFooter from "./AppointmentDetailsFooter";
import AppointmentPackageArticle from "./AppointmentPackageArticle";
import AppointmentPageSuccessBanner from "./AppointmentPageSuccessBanner";
import AppointmentServiceArticle from "./AppointmentServiceArticle";

export enum AppointmentPageOption {
  AppointmentConfirmation = "Appointment Confirmation",
  AppointmentDetails = "Appointment Details",
}

type AppointmentPageProps = {
  pageType: AppointmentPageOption;
};

const showSmallDownloadApp = Math.random() > 0.5;

const AppointmentPage = ({ pageType }: AppointmentPageProps) => {
  const { t } = useTranslation(["appointmentPage", "common"]);
  const dispatch = useDispatch();

  const { isAndroidDevice } = useDeviceDetection();
  const [open, setOpen] = useState(false);

  const { appointmentId } = useParams();

  const hasFetchedAppointments = useRef(false);

  const organization = useSelector(selectOrganization);

  const appointment = useSelector(selectAppointmentById(appointmentId || ""));

  const outlet = useSelector(selectOutletById(appointment?.outlet || 0));

  const articles = useSelector(selectArticlesByAppointmentId(appointmentId || ""));
  const slots = useSelector(selectArticleSlotsByArticleIds(articles.map((article) => article.id)));

  const onlinePayments = useSelector(selectOnlinePaymentsByAppointmentId(appointmentId || ""));
  const areOnlinePaymentsLoading = useSelector(selectOnlinePaymentIsLoading);

  const doesAllDataExist = appointment && articles.length && slots.length;

  const isHomeService = checkIsHomeServiceOrganization(organization.industry!);
  const [shouldShowSuccessBanner, setShouldShowSuccessBanner] = useState(
    pageType === AppointmentPageOption.AppointmentConfirmation
  );

  useEffect(() => {
    if (shouldShowSuccessBanner) {
      const timeout = setTimeout(() => {
        setShouldShowSuccessBanner(false);
      }, 4000);

      return () => clearTimeout(timeout);
    }
  }, [shouldShowSuccessBanner]);

  useEffect(() => {
    if (pageType === AppointmentPageOption.AppointmentConfirmation) {
      dispatch(clearCart());
    }
  }, [pageType, dispatch]);

  useEffect(() => {
    if (
      organization?.id &&
      organization?.name &&
      pageType === AppointmentPageOption.AppointmentDetails
    )
      trackEvent("Appointment Viewed", { id: organization.id, name: organization.name }, {});
  }, [organization, pageType]);

  useEffect(() => {
    if (!hasFetchedAppointments.current) {
      dispatch(getNormalizedAppointments({}));
      hasFetchedAppointments.current = true;
    }
  }, [dispatch]);

  if (!doesAllDataExist || !organization || !outlet || areOnlinePaymentsLoading)
    return <CentredSpinnerBox />;

  const dateToDisplay = appointment.appointmentTimeRange.startTime;
  const appointmentDateTime = getFormattedDateAndTime(dateToDisplay);

  const phoneNumber = outlet.contactNumbers.length ? outlet.contactNumbers[0].number : null;

  let totalPrice = 0.0;
  articles.forEach((article) => (totalPrice += +article.finalPrice));

  let downPayment = 0.0;

  onlinePayments.forEach((payment) => (downPayment += +payment.total));

  const currentTime = DateTime.local();
  const appointmentEndTime = DateTime.fromISO(appointment.appointmentTimeRange.endTime, {
    zone: "utc",
  });

  const isAnUpcomingAppointment = appointmentEndTime > currentTime;

  const isDetailsPage = pageType === AppointmentPageOption.AppointmentDetails;

  const shouldShowRescheduleSection = isDetailsPage && isAnUpcomingAppointment;

  const isAppointmentWithinCancellationTimeFrameOrPassed =
    currentTime.plus({ minutes: organization.cancellationTimeFrame }) >=
    DateTime.fromISO(dateToDisplay);

  const isAnUpcomingNewAppointment =
    appointment.status === AppointmentStatus.New &&
    !isAppointmentWithinCancellationTimeFrameOrPassed;

  // When on confirmation page and there was a down payment the cancel button does not show
  const shouldShowCancelButton =
    organization.allowCancellation &&
    isAnUpcomingNewAppointment &&
    ((pageType === AppointmentPageOption.AppointmentConfirmation && downPayment === 0) ||
      pageType === AppointmentPageOption.AppointmentDetails);

  const shouldShowGoToMyBookings =
    pageType === AppointmentPageOption.AppointmentConfirmation &&
    appointment.status === AppointmentStatus.New;

  const shouldShowBanner = organization.isMpVisible;
  const shouldShowLargeBanner = !showSmallDownloadApp && shouldShowBanner;
  const shouldShowSmallBanner = showSmallDownloadApp && shouldShowBanner;

  return (
    <Box bgcolor="bg.secondary">
      <CancelAppointmentModal open={open} setOpen={setOpen} />

      {shouldShowSmallBanner && <SmallMPBanner />}

      <Grid container justifyContent="center" bgcolor="#F6F7FC" spacing={3}>
        <Grid item xs={12}>
          <Box
            display="flex"
            width="100%"
            minHeight="190px"
            bgcolor="white"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            rowGap={1}
          >
            {getAppointmentStatusIcon(appointment.status, { width: 61, height: 61 })}

            <Typography variant="h4" align="center">
              {t("yourBookingIs", { status: t(`common:${appointment.status}`) })}
            </Typography>

            {(appointment.status === AppointmentStatus.New ||
              appointment.status === AppointmentStatus.Confirmed) && (
              <Typography variant="primary" align="center">
                {t("seeYouSoon")}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {shouldShowLargeBanner && (
        <Banner
          bgcolor="text.secondary"
          color="white"
          imgUrl="/images/DownloadAppBanner.svg"
          height="150px"
        >
          <Typography variant="h4">{t("bookingMadeEasier")}</Typography>
          <Typography variant="secondary">{t("downloadAppForFasterBooking")}</Typography>
          <Box mt={1}>
            <CallToActionLink
              href={organization.appLink || getUseAppLink(isAndroidDevice)}
              onClick={() => {
                ReactGA.event({
                  category: "Large",
                  action: "Confirmation Large App Download",
                  label: "Appointment",
                });
              }}
              paddingY={0.5}
              paddingX={1.5}
            >
              {t("common:download")}
            </CallToActionLink>
          </Box>
        </Banner>
      )}

      {isHomeService && appointment.address && (
        <Grid container item xs={12} bgcolor="white" spacing={2} p={2} my={1}>
          <Grid item xs={12}>
            <Stack>
              <AppointmentAddressSection
                address={appointment.address}
                selectedAddressId={appointment.address.id || 0}
              />
            </Stack>
          </Grid>
          <Divider />
        </Grid>
      )}

      <Grid container item xs={12} bgcolor="white" spacing={2} p={2} my={1}>
        <Grid item xs={12}>
          <AppointmentOrganizationHeader
            time={appointmentDateTime}
            outlet={outlet}
            shouldShowActions={!isHomeService}
          />
        </Grid>

        {shouldShowCancelButton && (
          <Grid item xs={12}>
            <CancelBookingCard handleClick={() => setOpen(true)} />
          </Grid>
        )}

        <Grid item xs={12} px={2}>
          <Typography variant="primary" fontWeight="bold" color="#848FA1">
            {t("reviewAppointmentPage:yourItems")}
          </Typography>
        </Grid>

        {articles.map((article) => {
          if (article.serviceVariant)
            return (
              <Grid key={article.id} item xs={12}>
                <AppointmentServiceArticle
                  article={article}
                  slots={slots.filter((slot) => slot.article === article.id)}
                  isDetailsPage={isDetailsPage}
                />
              </Grid>
            );
          else if (article.packageVariant)
            return (
              <Grid key={article.id} item xs={12}>
                <AppointmentPackageArticle
                  article={article}
                  slots={slots.filter((slot) => slot.article === article.id)}
                  isDetailsPage={isDetailsPage}
                />
              </Grid>
            );
          else return null;
        })}
      </Grid>

      <CancellationPolicySection />

      <AppointmentDetailsFooter
        phoneNumber={phoneNumber}
        notes={appointment.notes}
        downPayment={downPayment}
        totalPrice={totalPrice}
        shouldShowRescheduleSection={shouldShowRescheduleSection}
        shouldShowGoToMyBookings={shouldShowGoToMyBookings}
      />
      {shouldShowSuccessBanner && <AppointmentPageSuccessBanner status={appointment.status} />}
    </Box>
  );
};

const Banner = styled(
  Box,
  dontForwardProps("imgUrl")
)(({ imgUrl }: { imgUrl: string }) => ({
  backgroundImage: `url(${imgUrl})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: 5,
  paddingLeft: "50%",
  paddingRight: "5%",
}));
export default AppointmentPage;
