import { BrowserAgent } from "@newrelic/browser-agent";

// Populate using values in copy-paste JavaScript snippet.
const options = {
  // NREUM.init
  init: {
    session_replay: {
      enabled: true,
      block_selector: "",
      mask_text_selector: "*",
      sampling_rate: 10.0,
      error_sampling_rate: 100.0,
      mask_all_inputs: true,
      collect_fonts: true,
      inline_images: false,
      inline_stylesheet: true,
      mask_input_options: {},
    },
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ["bam.nr-data.net"] },
  },
  // NREUM.info
  info: {
    beacon: "bam.nr-data.net",
    errorBeacon: "bam.nr-data.net",
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
    sa: 1,
  },
  // NREUM.loader_config
  loader_config: {
    accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
    trustKey: process.env.REACT_APP_NEW_RELIC_TRUST_KEY,
    agentID: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
  },
};

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_NEW_RELIC_LICENSE_KEY &&
  process.env.REACT_APP_NEW_RELIC_APPLICATION_ID &&
  process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID &&
  process.env.REACT_APP_NEW_RELIC_TRUST_KEY &&
  process.env.REACT_APP_NEW_RELIC_AGENT_ID
) {
  // The agent loader code executes immediately on instantiation.
  new BrowserAgent(options);
}
